<template>
  <!-- 我的车辆 -->
  <div class="container">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList">

      <div class="car-box" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
        <div class="left">
          <div class="top">
            <div class="title van-ellipsis">{{ item.carNum }}</div>
          </div>
          <span>{{ item.carBrand + '' + item.carModel}}</span>
          <span>车辆颜色：{{ item.carColour }}</span>
          
        </div>

        <img :src="item.carPic" alt="">

        <!-- <div class="time">
          <van-button block type="info">编辑</van-button>
          <van-button block type="info">删除</van-button>
        </div> -->
      </div>
    </van-list>

  </div>
</template>
<script>
import { getMyCar } from '@/api/houseApi'
export default {
  props: {
    category: {
      type: [Number],
      required: false,
      default: 0,
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      currentList: -1,
    }
  },

  created() {
    // this.getList()
  },
  mounted() {},
  methods: {
    goDetail(id) {
      this.$router.push({
        // path: '/' + this.detailPath + '?id=' + id,
        path: '/addCar?id=' + id,
      })
    },
    getList() {
      if (this.currentList !== 0) {
        getMyCar().then(res => {
          if (res.code === 200) {
            this.currentList = -1
            this.loading = false
            this.finished = true
            this.list = this.list.concat(res.data)
            this.page++
          }
        })
      } else {
      }
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.van-cell__value {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.ml {
  margin-left: 5px;
}
.car-box {
  width: 94%;
  margin-left: 3%;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 12px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 5px 5px #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      .title {
        flex: 1;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  img {
    width: auto;
    height: 60px;
    border-radius: 5px;
  }
 
}
.add {
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>
